@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
}

.errorBlock {
  background-color: var(--shape-10);

  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 420px;
  width: 100%;
  padding: 16px 48px 24px;

  border-radius: 8px;

  & h4 {
    @include font-style-h4($font-weight-regular);

    color: var(--text-10);

    margin: 0;
    padding-bottom: 8px;
  }

  & p,
  .refreshButton {
    @include font-style-default;
  }

  & p {
    color: var(--text-10);
    text-align: center;
    margin: 0;
    padding-bottom: 16px;
  }
}

.refreshButton {
  color: var(--text-link);

  display: flex;
  align-items: center;

  cursor: pointer;

  & span {
    margin-left: 5px;
  }

  &:hover span svg,
  &:focus span svg {
    fill: var(--system-error);
  }
}

.icon {
  color: var(--text-link);
}
